import { ui } from "@clientio/rappid";
export class XrefMenuService {
    static actions = [
        { action: 'reference', content: 'Reference' },
        { action: 'compete', content: 'Compete' },
        { action: 'synchronise', content: 'Synchronise' },
        { action: 'reversion', content: 'Reversion' },
        { action: 'terminate', content: 'Terminate' },
        { action: 'timing', content: 'Timing' },
    ];
    contextElement;
    create(x, y) {
        const contextToolbar = new ui.ContextToolbar({
            target: { x, y }, // or this can be a HTMLElement, but not a jointjs Element!
            vertical: true,
            anchor: 'top',
            scale: 1,
            tools: XrefMenuService.actions,
        });
        contextToolbar.render();
        return contextToolbar;
    }
}
