// TODO could store the function last used and its params (memoized) so that we can refresh the colourBy data from the API without
// having to ask the user again ( to avoid initializeColourByOnLoad())
import { TestednessColourBy } from '@/views/Behaviour/JointJSGraph/components/ColourBy/definitions/TestednessColourBy';
import { EnablementColourBy } from '@/views/Behaviour/JointJSGraph/components/ColourBy/definitions/EnablementColourBy';
export const keySets = [
    {
        name: 'node',
        title: 'Node Type',
        prefix: 'bn-',
        modal: '',
        nodeProperty: 'type',
        items: [
            'Condition',
            'Event',
            'GuardedEvent',
            'State',
            'Input',
            'Output',
            'Assertion',
            'Quantification',
            'Quantity',
            'Function',
        ],
    },
    {
        name: 'validity',
        title: 'Validity',
        prefix: 'bn-',
        modal: '',
        nodeProperty: 'validity',
        items: [
            {
                name: 'Valid',
                description: 'Valid',
                explanation: 'No open issues',
                cssName: 'valid',
            },
            {
                name: 'Minor',
                description: 'Minor',
                explanation: 'Minor validity based on issue status',
                cssName: 'minor',
            },
            {
                name: 'Implied',
                description: 'Implied',
                explanation: 'Implied validity based on linked issues',
                cssName: 'implied',
            },
            {
                name: 'Invalid',
                description: 'Invalid',
                explanation: 'Invalid',
                cssName: 'invalid',
            },
        ],
    },
    TestednessColourBy,
    {
        name: 'coverage',
        title: 'Coverage',
        prefix: 'bn-',
        modal: 'specpick-modal',
        nodeProperty: 'requirements',
        resultMapper: (node, transformedData) => {
            return node['requirements']?.find(r => r.spec_id === transformedData) ? 'inspec' : '';
        },
        items: [
            'In Spec',
            'Not in Spec',
        ],
    },
    {
        name: 'search-req',
        title: 'Requirements',
        prefix: 'bn-',
        modal: '',
        nodeProperty: 'search',
        items: [
            'Found',
            'NoMatch',
        ],
        sidebarConfig: {
            objectType: 'Requirement',
            nameProperty: 'display_id',
            textProperty: 'object_text,'
        }
    },
    {
        name: 'search-test',
        title: 'Tests',
        prefix: 'bn-',
        modal: '',
        nodeProperty: 'search',
        items: [
            'Found',
            'NoMatch',
        ],
        sidebarConfig: {
            objectType: 'TestCase',
            nameProperty: 'name',
            textProperty: 'test,'
        }
    },
    EnablementColourBy,
    {
        name: 'entities',
        title: 'Entities',
        prefix: 'bne-',
        modal: 'colour-by-entity-modal',
        transformData: (data) => {
            return data.reduce((dest, cpt) => dest.concat(cpt.behaviours), []);
        },
        resultMapper: (node, transformedData) => {
            const entity = transformedData.find(bn => bn.bn_id === node.id);
            return entity ? entity.role_type.toLocaleLowerCase() : '';
        },
        nodeProperty: 'entity',
        items: [
            'Performer',
            'Function',
            'Object',
            'Enabler',
            'Reference',
            'Objective',
            'Beneficiary',
        ],
    },
];
