<template>
  <div>
    <div v-show="isKeyVisible" class="colour-key vertical-key px-0">
      <p class="font-weight-bold px-75 mb-25">
        Colour Legend
      </p>
      <span :class="`colour-key-all ${outerPrefix}-${currentSet.name}`">
      <span
        v-for="n in currentSet.items"
        :key="n.name || n"
        :class="`colour-key-item ${currentSet.prefix}${(n.name || n).replace(/\W/g, '').toLocaleLowerCase()}`"
        @click="clickColourKeyItem(n.name || n)"
        v-b-popover.hover.right="n.explanation || ''"
      >
        {{ getText(n, currentSet.transformedData) }}
      </span>
    </span>
      <portal to="bt-colour-by-dropdown-items">
        <b-dd variant="outline-secondary" :text="'Colour by ' + currentSet.title">
          <b-dropdown-item v-for="keySet in keySets" :key="keySet.name" @click="changeColourBySet(keySet)">
            {{ keySet.title }}
          </b-dropdown-item>
          <b-dropdown-divider/>
          <b-dropdown-item @click="() => isKeyVisible = !isKeyVisible">
            {{ isKeyVisible ? 'Hide' : 'Show' }} Legend
          </b-dropdown-item>
        </b-dd>
      </portal>
      <div v-if="treeData">
        <ShowTestsModal @testedness="colourByDataLoaded"/>
        <ShowEnablementModal @enablement="colourByDataLoaded"/>
        <ColourByEntityModal @colourByEntities="colourByDataLoaded" :behaviour-tree-id="treeData.id"/>
        <template>
          <b-modal
            id="specpick-modal"
            ok-only
            title="Colour by Specification coverage"
            ok-title="Colour by coverage"
            ok-variant="success"
            cancel-title="Dismiss"
            cancel-variant="outline-secondary"
            @ok="colourByDataLoaded(colourBySpecification.id)"
          >
            <b-form-group
              label="Colour behaviour nodes by requirements in the selected specification"
              label-for="config-select"
            >
              <SpecificationPicker v-model="colourBySpecification"/>
            </b-form-group>
          </b-modal>
        </template>
      </div>
    </div>
    <b-sidebar v-if="colourBySidebarVisible && currentSet.sidebarConfig"
               id="behaviourTreeSidebarSearch"
               width="550px"
               bg-variant="white"
               :key="`${currentSet.name}ColourBySidebar`"
               right
               :visible="colourBySidebarVisible"
               @hidden="colourBySidebarVisible = false"
    >
      <resize-bar sidebar-id="behaviourTreeSidebarSearch"/>
      <ColourBySearchSidebar :bt-id="$route.params.behaviourTreeId" :node-map="nodeMap"
                             :object-type="currentSet.sidebarConfig.objectType"
                             :key="`${currentSet.name}ColourBySidebarResults`"
                             :object-name-property="currentSet.sidebarConfig.nameProperty"
                             :object-text-property="currentSet.sidebarConfig.textProperty"
                             @foundBehaviourNodes="colourByDataLoaded"
                             @nodeFocus="n => focusFn(n)"
      />
    </b-sidebar>
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import { keySets } from '@/views/Behaviour/JointJSGraph/components/ColourBy/KeySets'
import Vue from 'vue'
import store from '@/store'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ShowTestsModal from '@/components/Behaviours/Modals/showTests.vue'
import ColourByEntityModal from '@/views/Behaviour/JointJSGraph/components/ColourBy/ColourByEntityModal.vue'
import ShowEnablementModal from '@/components/Behaviours/Modals/ShowEnablement.vue'
import { useColourBy } from '@/components/Generic/Graph/useColourBy'
import ColourBySearchSidebar from '@/views/Behaviour/JointJSGraph/components/ColourBy/ColourBySearchSidebar.vue'
import ResizeBar from '@core/components/resize-bar.vue'
import { behaviourNodeStyleDefinitions } from '@/views/Behaviour/JointJSGraph/behaviourNode'

export default Vue.extend({
  name: 'BehaviourColourKey',
  components: {
    ResizeBar,
    ColourBySearchSidebar,
    ShowEnablementModal,
    ColourByEntityModal,
    ShowTestsModal,
    SpecificationPicker,
  },
  props: {
    treeData: { type: Object },
    graph: { type: Object, required: true },
    nodeMap: { type: Object, required: true },
    refreshColourBy: { type: Boolean, required: true },
    focusFn: { type: Function, required: true },
    outerPrefix: { type: String, default: () => 'bn' },
  },
  setup(props, context) {
    const currentSet = ref(keySets.find(ks => ks.name === 'node'))
    const { emit } = context
    onMounted(async () => {
      const loadedSet = keySets.find(ks => ks.name === localStorage.getItem(`selectedColourBy${props.outerPrefix}`))
      if (loadedSet) {
        currentSet.value = loadedSet
      }
    })

    function buildNodeCss(node, style = null) {
      const _style = style || behaviourNodeStyleDefinitions.find(d => d.title === node.type)
      const customBodyCss = `${_style?.cssClass}`
      return customBodyCss
    }

    const {
      setTreeCss,
      getText,
      clickColourKeyItem,
      changeColourBySet,
      colourByDataLoaded,
      isKeyVisible,
      colourBySidebarVisible,
    } = useColourBy(context, props, store, props.graph, props.treeData, currentSet, buildNodeCss)
    const colourBySpecification = ref()
    return {
      colourBySpecification,
      isKeyVisible,
      currentSet,
      keySets,
      getText,
      clickColourKeyItem,
      changeColourBySet,
      colourByDataLoaded,
      colourBySidebarVisible,
    }
  },
})
</script>
<style scoped lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu';

.colour-key {
  display: inline-block;
  padding-left: 0.25rem;
  padding-bottom: 0 !important;
  height: 2rem;
  overflow: hidden;
  border-bottom-right-radius: 0.428rem;
  border-top-right-radius: 0.428rem;
  background: $card-bg;

  .colour-key-all {
    display: inline-block;
    border: none;

    .colour-key-item {
      display: inline-block;
      padding: 0.125rem;
      color: white !important;
      text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.8);
      font-weight: normal;
    }
  }
}

// change the all display:s to block (no inline)
// and position: relative to get horizontal key (that runs out of room)

.colour-key.vertical-key {
  display: block;
  height: revert;
  background: $body-bg;
  position: absolute;
  padding: 0.5rem;
  left: 0;
  bottom: 15rem;
  z-index: 99;

  .colour-key-all {
    display: block;

    .colour-key-item {
      display: block;
      padding: 0.5rem;
    }
  }
}

@media (max-height: 1124px) {
  .colour-key.vertical-key {
    padding: 0.125rem;
    bottom: 15rem;
    font-size: 0.8rem;

    .colour-key-all {
      .colour-key-item {
        padding: 0.125rem;
        font-size: smaller;
      }
    }
  }
}

@media (min-height: 1124px) {
  .colour-key.vertical-key {
    padding: 0.5rem;
    bottom: 15rem;
    font-size: 1rem;

    .colour-key-all {
      .colour-key-item {
        padding: 0.5rem;
        font-size: small;
      }
    }
  }
}

body.dark-layout {
  .colour-key {
    background: $theme-dark-card-bg;
  }
}
</style>
