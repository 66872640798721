import { dia, ui, shapes } from '@clientio/rappid';
import * as appShapes from "@/views/Behaviour/JointJSGraph/CustomShapes/customShapeFunctions";
import { behaviourNodeStyleDefinitions } from "@/views/Behaviour/JointJSGraph/behaviourNode";
import { createCustomShape, createXrefLink } from "@/views/Behaviour/JointJSGraph/CustomShapes/customShapeFunctions";
/*
 * BehaviourTree stencil service
 * Creates a new stencil and renders it to the given paper
 * - Fills the stencil sidebar ("toolbox") with various shapes and their metadata
 * - Allows dropping of new elements and callbacks for handling their creation
 * - Handles dropping of new links (cross-references and timing)
 */
export class StencilService {
    paper;
    stencil;
    dragEndCallback;
    isDraggingLink;
    draggingLinkView;
    getLinkStartElementFn;
    createStencil(paper, dragEndCallback, getLinkStartElementFn) {
        this.paper = paper;
        this.dragEndCallback = dragEndCallback;
        this.getLinkStartElementFn = getLinkStartElementFn;
        this.stencil = new ui.Stencil({
            paper: this.paper,
            paperOptions: function () {
                return {
                    model: new dia.Graph({}, {
                        cellNamespace: appShapes
                    }),
                    cellViewNamespace: appShapes
                };
            },
            width: 140,
            groups: {
                nodesGroup: { label: 'Nodes', index: 1, height: 420 },
                linksGroup: { label: 'Links', index: 2, height: 130 },
            },
            dragEndClone: element => {
                // element can be null if drag does not find a target
                return null;
            },
            search: {
                '*': ['type', 'attrs/text/text', 'attrs/root/title', 'attrs/label/text'],
            },
            layout: {
                columnWidth: 60,
                columns: 2,
                rowHeight: 60,
                rowGap: 20,
                columnGap: 10,
                deep: true,
                resizeToFit: true,
            },
            scaleClones: true,
        });
        this.stencil.on('element:dragstart', (cloneView, evt, dropArea, validDropTarget) => {
            this.onDragStart(cloneView, paper);
        });
        this.stencil.on('element:drag', (cloneView, evt, dropArea, validDropTarget) => {
            this.whileDragging(evt, cloneView);
        });
        this.stencil.on('element:dragend', (cloneView, evt, dropArea, validDropTarget) => {
            this.onDragEnd(validDropTarget, dropArea, cloneView);
        });
        this.stencil.render();
        this.stencil.startListening();
        return this.stencil;
    }
    onDragStart(cloneView, paper) {
        const { tag } = cloneView.model.attributes.attrs;
        const linkStartElement = this.getLinkStartElementFn();
        if ((tag?.type === 'xref' || tag?.type === 'timing') && linkStartElement) {
            this.isDraggingLink = true;
            const link = createXrefLink(cloneView, null, tag?.type === 'xref' ? 'blue' : 'orange');
            link.source(linkStartElement);
            paper.model.addCell(link);
            this.draggingLinkView = paper.findViewByModel(link);
        }
        else {
            this.isDraggingLink = false;
            try {
                this.draggingLinkView = null;
            }
            catch (e) {
                console.log('Could not set linkview to null');
            }
        }
    }
    whileDragging(evt, cloneView) {
        if (this.isDraggingLink && this.draggingLinkView) {
            const tgt = this.paper.clientToLocalPoint({ x: evt.clientX, y: evt.clientY });
            this.draggingLinkView.model.target(tgt);
        }
        cloneView.vel.attr('opacity', 1);
    }
    onDragEnd(validDropTarget, dropArea, cloneView) {
        console.log('validDropTarget', validDropTarget);
        if (validDropTarget) {
            const views = this.paper.findViewsInArea(dropArea);
            const targetElement = views.find(v => v.model.isElement);
            if (targetElement) {
                this.dragEndCallback(cloneView, targetElement);
            }
            else {
                console.log('Cancelled drag from stencil');
            }
            try {
                cloneView.model.remove();
                cloneView.model = null;
            }
            catch (e) {
                console.log('Remove temp cloneview from graph failed, probably already removed ');
            }
            // Finished handling, so cancel drag to prevent the builtin rappid drop event from failing
            this.stencil.cancelDrag();
        }
        if (this.draggingLinkView) {
            this.draggingLinkView.remove();
            this.draggingLinkView = null;
            this.isDraggingLink = false;
        }
    }
    generateStencilNodes() {
        return behaviourNodeStyleDefinitions.map(def => {
            const r = createCustomShape(null, def);
            r.attr('root/title', def.title);
            const symbols = def.symbol_l.startsWith('?') || def.symbol_l.startsWith('!')
                ? def.symbol_l : def.symbol_l + def.symbol_r;
            r.attr('header/text', symbols);
            r.attr('header/font-size', 16);
            r.attr('header/font-weight', 'bold');
            r.attr('body/class', def.cssClass);
            r.attr('body/magnet', 'passive');
            r.attr('tag', def);
            r.attr('text/text', def.short);
            r.attr('text/font-size', 11);
            r.attr('text/y', '5em');
            r.attr('text/text-anchor', 'left');
            r.attr('validityBadge/fill-opacity', 0);
            r.attr('validityText/text', '');
            return r;
        });
    }
    generateStencilLinks() {
        const pathCmd = 'M 30 0 L 60 30 30 60 0 30';
        const diamond = new shapes.basic.Path({
            size: { width: 100, height: 100 },
            attrs: {
                path: {
                    d: pathCmd,
                    strokeDasharray: '8 8',
                    fill: 'transparent',
                    stroke: 'blue',
                    strokeWidth: 4,
                },
                text: {
                    text: 'X-Ref',
                    'ref-y': .5,
                    fill: 'white',
                },
                tag: { type: 'xref' }, // this tag is hard-coded elsewhere for logic that handles drag-dropping links
            }
        });
        const diamond2 = new shapes.basic.Path({
            size: { width: 100, height: 100 },
            attrs: {
                path: {
                    d: pathCmd,
                    strokeDasharray: '4 4',
                    fill: 'transparent',
                    stroke: 'orange',
                    strokeWidth: 4,
                },
                text: {
                    text: 'Timing',
                    'ref-y': .5,
                    fill: 'white',
                },
                tag: { type: 'timing' },
            }
        });
        return [diamond, diamond2];
    }
    loadStencil() {
        this.stencil.load(this.generateStencilNodes(), 'nodesGroup');
        this.stencil.load(this.generateStencilLinks(), 'linksGroup');
    }
}
