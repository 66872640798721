<template>
  <generic-associator id="colour-by-entity-modal"
                      title-override="Colour By Entities"
                      :fetch-fn="fetchEntities"
                      :update-fn="submitColourBy"
                      type-name="Entity"
                      filter-property="name"
                      :initial-list="entities"
                      :prefilter-enabled="false"
                      :label-filter-enabled="true"
                      :instant-save="false">
    <template #left="cpt">
      <div :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ cpt.name }} </div>
      <div class="text-muted ml-1">({{ cpt.behaviours.length }}) {{ cpt.labels.join(', ') }}</div>
    </template>
    <template #right="{ cpt }">
      <p :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ cpt.name }} </p>
    </template>
  </generic-associator>
</template>

<script>
import { ref } from '@vue/composition-api'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'
import coreService from '@/libs/api-services/core-service'

// Modal component for the user to pick which Entities they want to see on the BT
// Use different colours based on the entity's role (e.g. performer, enabler etc.)
export default {
  name: 'ColourByEntityModal',
  components: { GenericAssociator },
  props: {
    behaviourTreeId: {
      required: true,
      type: String,
    },
  },
  setup(props, { emit }) {
    const entities = ref([])

    function fetchEntities() {
      return coreService.behavioursApi.getBtComponents(props.behaviourTreeId)
    }

    function submitColourBy(payload, selectedLabels) {
      emit('colourByEntities', payload, selectedLabels)
    }

    return {
      submitColourBy,
      fetchEntities,
      entities,
    }
  },
}
</script>
