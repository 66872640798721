export const EnablementColourBy = {
    name: 'enablement',
    title: 'Enablement',
    prefix: 'bn-',
    modal: 'show-enablement-modal',
    nodeProperty: 'enablement',
    transformData: (data) => {
        if (data.config !== 'trl' && data.config !== 'status')
            return [];
        data.data.config = data.config;
        return data.data;
    },
    resultMapper: (node, transformedData) => {
        return transformedData[node.id]?.replace(/\W/g, '') || '';
    },
    generateItems: (data) => {
        return data.config === 'trl' ? [
            'Unevaluated',
            'Research',
            'Concept',
            'Prototype',
            'Qualified',
            'Proven',
        ]
            :
                [
                    'No Enablers',
                    'Unevaluated',
                    'Failed / Non-operational',
                    'Degraded (Significant)',
                    'Degraded (Minor)',
                    'Fully Operational',
                ];
    },
    items: [],
};
