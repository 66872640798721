export const TestednessColourBy = {
    name: 'testedness',
    title: 'Testedness',
    modal: 'show-tests-modal',
    nodeProperty: 'testState',
    prefix: 'bn-test-',
    getDescription: (n, data) => {
        if (typeof n !== 'string')
            return n.description;
        const tp = data;
        const percentMap = [['Passed', `${n} - ${tp.passPercentage}%`],
            ['Inconclusive', `Not Run - ${tp.noRunPercentage}%`],
            ['Partial', `${n} - ${tp.partialPercentage}%`],
            ['Failed', `${n} - ${tp.failPercentage}%`],
            ['No Test', `N/A - ${tp.naPercentage}%`]];
        return percentMap.find(x => x[0] === n)[1];
    },
    transformData: (data) => {
        const results = {};
        const tstates = Object.values(data);
        const total = tstates.length;
        const toPct = fn => ((tstates.filter(fn)?.length * 100) / total).toFixed(1);
        results.passPercentage = toPct(k => k === 'Passed');
        results.failPercentage = toPct(k => k === 'Failed');
        results.noRunPercentage = toPct(k => k === 'Inconclusive' || k === 'No Run'
            || k === 'Blocked' || k === 'N/A');
        results.partialPercentage = toPct(k => k === 'Partial');
        results.naPercentage = '0';
        // calculate naPercentage as the remaining percentage after
        // summing all other found percentages
        const sumPctFoundTests = Number(Object.values(results)
            .reduce((r, t) => Number(r) + Number(t), 0));
        results.naPercentage = (100 - sumPctFoundTests).toFixed(1);
        results.testDataMap = data;
        return results;
    },
    resultMapper: (node, transformedData) => {
        return transformedData.testDataMap[node.id] || '';
    },
    items: [
        'Passed',
        'Failed',
        'Inconclusive',
        'Partial',
        'No Test',
    ],
};
