import { renderMenu } from '@/components/Generic/Graph/services/contextMenuHelper';
export class ContextToolbarService {
    contextElement;
    createContextMenu(node, parentElement, lastX, lastY) {
        this.contextElement = node;
        const tools = [
            { action: 'addChild', content: 'Add Child' },
            { action: 'duplicateNode', content: 'Duplicate' },
            { action: 'copyNode', content: 'Copy To...' },
            { action: 'deleteNode', content: 'Delete Node' },
            { action: 'addPrecondition', content: 'Add Precondition' },
            { action: 'refineBehaviour', content: 'Refine Behaviour' },
            { action: 'selectWithChildren', content: 'Select With Children' },
            { action: 'selectChildren', content: 'Select Children' },
            { action: 'expandBranch', content: 'Expand' },
            // TODO future work
            // {action: 'instantiateFunction', content: 'Instantiate Function'},
            // {action: 'instantiateInterface', content: 'Instantiate Interface'},
        ];
        return renderMenu(tools, lastX, lastY);
    }
}
